@font-face {
  font-family: 'pcbbalance';
  src: url('../font/pcbbalance.eot?63189034');
  src: url('../font/pcbbalance.eot?63189034#iefix') format('embedded-opentype'),
       url('../font/pcbbalance.woff2?63189034') format('woff2'),
       url('../font/pcbbalance.woff?63189034') format('woff'),
       url('../font/pcbbalance.ttf?63189034') format('truetype'),
       url('../font/pcbbalance.svg?63189034#pcbbalance') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'pcbbalance';
    src: url('../font/pcbbalance.svg?63189034#pcbbalance') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "pcbbalance";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-minus-circle-light:before { content: '\e800'; } /* '' */
.icon-minus-circle-regular:before { content: '\e801'; } /* '' */
.icon-minus-circle-solid:before { content: '\e802'; } /* '' */
.icon-minus-hexagon-light:before { content: '\e803'; } /* '' */
.icon-minus-hexagon-regular:before { content: '\e804'; } /* '' */
.icon-minus-hexagon-solid:before { content: '\e805'; } /* '' */
.icon-trash-alt-light:before { content: '\e806'; } /* '' */
.icon-trash-alt-regular:before { content: '\e807'; } /* '' */
.icon-trash-alt-solid:before { content: '\e808'; } /* '' */
.icon-shredder-light:before { content: '\e809'; } /* '' */
.icon-shredder-regular:before { content: '\e80a'; } /* '' */
.icon-shredder-solid:before { content: '\e80b'; } /* '' */
.icon-info-circle-light:before { content: '\e80c'; } /* '' */
.icon-info-circle-regular:before { content: '\e80d'; } /* '' */
.icon-info-circle-solid:before { content: '\e80e'; } /* '' */
.icon-question-circle-light:before { content: '\e80f'; } /* '' */
.icon-question-circle-regular:before { content: '\e810'; } /* '' */
.icon-question-circle-solid:before { content: '\e811'; } /* '' */
.icon-linked-light:before { content: '\e812'; } /* '' */
.icon-linked-regular:before { content: '\e813'; } /* '' */
.icon-unlinked-light:before { content: '\e814'; } /* '' */
.icon-unlinked-regular:before { content: '\e815'; } /* '' */
.icon-linked-solid:before { content: '\e816'; } /* '' */
.icon-unlinked-solid:before { content: '\e817'; } /* '' */
.icon-wrench-outline:before { content: '\e818'; } /* '' */
.icon-undo:before { content: '\e819'; } /* '' */
.icon-home-outline:before { content: '\e81a'; } /* '' */
.icon-right:before { content: '\e820'; } /* '' */
.icon-lock-open-alt:before { content: '\e821'; } /* '' */
.icon-duplicate:before { content: '\e825'; } /* '' */
.icon-close-small:before { content: '\e829'; } /* '' */
.icon-close:before { content: '\e82a'; } /* '' */
.icon-view:before { content: '\e82d'; } /* '' */
.icon-folder:before { content: '\e82e'; } /* '' */
.icon-open:before { content: '\e82f'; } /* '' */
.icon-mail:before { content: '\e830'; } /* '' */
.icon-new-folder:before { content: '\e831'; } /* '' */
.icon-save:before { content: '\e833'; } /* '' */
.icon-settings:before { content: '\e834'; } /* '' */
.icon-twitter:before { content: '\e836'; } /* '' */
.icon-check-small:before { content: '\e837'; } /* '' */
.icon-check:before { content: '\e838'; } /* '' */
.icon-web:before { content: '\e83a'; } /* '' */
.icon-file-archive-light:before { content: '\e83b'; } /* '' */
.icon-chevron-circle-down-regular:before { content: '\e83c'; } /* '' */
.icon-arrow-circle-down-regular:before { content: '\e83d'; } /* '' */
.icon-file-download-regular:before { content: '\e83e'; } /* '' */
.icon-cloud-download-alt-regular:before { content: '\e83f'; } /* '' */
.icon-download-light:before { content: '\e840'; } /* '' */
.icon-folder-light:before { content: '\e841'; } /* '' */
.icon-archive-light:before { content: '\e842'; } /* '' */
.icon-cart-plus-regular:before { content: '\e843'; } /* '' */
.icon-angle-right-solid:before { content: '\e844'; } /* '' */
.icon-angle-left-solid:before { content: '\e845'; } /* '' */
.icon-angle-down-solid:before { content: '\e846'; } /* '' */
.icon-angle-up-solid:before { content: '\e847'; } /* '' */
.icon-caret-up-solid:before { content: '\e848'; } /* '' */
.icon-caret-down-solid:before { content: '\e849'; } /* '' */
.icon-caret-left-solid:before { content: '\e84a'; } /* '' */
.icon-caret-right-solid:before { content: '\e84b'; } /* '' */
.icon-file-import-regular:before { content: '\e84c'; } /* '' */
.icon-cloud-upload-alt-regular:before { content: '\e84d'; } /* '' */
.icon-plus-solid:before { content: '\e84e'; } /* '' */
.icon-plus-light:before { content: '\e84f'; } /* '' */
.icon-copy-light:before { content: '\e850'; } /* '' */
.icon-times-light:before { content: '\e851'; } /* '' */
.icon-times-solid:before { content: '\e852'; } /* '' */
.icon-badge-check-light:before { content: '\e853'; } /* '' */
.icon-badge-check-solid:before { content: '\e855'; } /* '' */
.icon-check-circle-solid:before { content: '\e856'; } /* '' */
.icon-check-circle-light:before { content: '\e857'; } /* '' */
.icon-check-circle-regular:before { content: '\e858'; } /* '' */
.icon-check-regular:before { content: '\e859'; } /* '' */
.icon-times-circle-light:before { content: '\e85a'; } /* '' */
.icon-times-regular:before { content: '\e85b'; } /* '' */
.icon-times-square-regular:before { content: '\e85c'; } /* '' */
.icon-ellipsis-h-light:before { content: '\e85d'; } /* '' */
.icon-ellipsis-h-regular:before { content: '\e85e'; } /* '' */
.icon-ellipsis-h-solid:before { content: '\e85f'; } /* '' */
.icon-ellipsis-v-light:before { content: '\e860'; } /* '' */
.icon-ellipsis-v-regular:before { content: '\e861'; } /* '' */
.icon-ellipsis-v-solid:before { content: '\e862'; } /* '' */
.icon-sliders-h-regular:before { content: '\e863'; } /* '' */
.icon-sliders-v-regular:before { content: '\e864'; } /* '' */
.icon-flask-solid:before { content: '\e865'; } /* '' */
.icon-sliders-v-square-regular:before { content: '\e866'; } /* '' */
.icon-user-light:before { content: '\e867'; } /* '' */
.icon-user-regular:before { content: '\e868'; } /* '' */
.icon-user-solid:before { content: '\e869'; } /* '' */
.icon-user-circle-light:before { content: '\e86a'; } /* '' */
.icon-user-circle-regular:before { content: '\e86b'; } /* '' */
.icon-user-circle-solid:before { content: '\e86c'; } /* '' */
.icon-sign-out-solid:before { content: '\e86d'; } /* '' */
.icon-times-circle-solid:before { content: '\e86e'; } /* '' */
.icon-times-circle-regular:before { content: '\e86f'; } /* '' */
.icon-search-minus-light:before { content: '\e870'; } /* '' */
.icon-search-minus-regular:before { content: '\e871'; } /* '' */
.icon-search-minus-solid:before { content: '\e872'; } /* '' */
.icon-search-plus-light:before { content: '\e873'; } /* '' */
.icon-search-plus-regular:before { content: '\e874'; } /* '' */
.icon-search-plus-solid:before { content: '\e875'; } /* '' */
.icon-search-light:before { content: '\e876'; } /* '' */
.icon-search-regular:before { content: '\e877'; } /* '' */
.icon-search-solid:before { content: '\e878'; } /* '' */