/* Payment Request */
#payment {
  &.busy {
    opacity: 0.3;
  }

  .card-errors {
    margin-top: 10px;
    font-size: 13px;
  }

  fieldset {
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 4px 6px 0 rgba(112, 157, 199, 0.15);
    border-radius: 4px;
    border: none;

    &.card-wrapper {
      padding: 10px;
    }

    label {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 42px;
      padding: 10px 0;
      align-items: center;
      justify-content: center;
      color: #8898aa;
      font-weight: 400;

      &:not(:last-child) {
        border-bottom: 1px solid #f0f5fa;
      }

      &.state {
        display: inline-flex;
        width: 75%;
      }
    }

    label.zip {
      display: inline-flex;
      width: 25%;
      padding-right: 60px;
    }

    &:not(.with-state) label.zip {
      width: 100%;
    }

    label span {
      min-width: 125px;
      padding: 0 0 0 15px;
      text-align: right;
    }

    .redirect label span {
      width: 100%;
      text-align: center;
    }
  }

  p {
    &.instruction {
      display: inline-table;
      margin-top: -32px;
      padding: 0 5px;
      text-align: center;
      background: #f8fbfd;
    }

    &.tip {
      margin: -10px auto 10px;
      padding: 5px 0 5px 30px;
      font-size: 14px;
      background: url(../../assets/svgs/tip.svg) left center no-repeat;
    }
  }

  .field {
    flex: 1;
    padding: 0 15px;
    background: transparent;
    font-weight: 400;
    color: #31325f;
    outline: none;
    cursor: text;

    &::-webkit-#003E4A-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: #aab7c4;
    }
  }

  fieldset .select::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    right: 20px;
    top: 50%;
    margin-top: -2px;
    background-image: url(../../assets/svgs/dropdown.svg);
    pointer-events: none;
  }

  input {
    flex: 1;
    border-style: none;
    outline: none;
    color: #313b3f;
    font-family: 'Titillium Web';
    font-size: 16px;
  }

  select {
    flex: 1;
    border-style: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    color: #313b3f;
    cursor: pointer;
    background: transparent;
    font-family: 'Titillium Web';

    &:focus {
      color: #003e4a;
    }
  }

  ::-webkit-input-placeholder {
    color: #cfd7e0;
  }

  ::-moz-placeholder {
    color: #cfd7e0;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #cfd7e0;
  }

  input:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-text-fill-color: #003e4a;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out 1s;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  #card-element {
    margin-top: -1px;
  }

  #ideal-bank-element {
    padding: 0;
  }

  button {
    display: block;
    background: #003e4a;
    color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    font-weight: 700;
    width: 100%;
    height: 40px;
    outline: none;
    cursor: pointer;
    transition: all 0.15s ease;

    &:focus {
      background: #555abf;
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    }

    &:active {
      background: #43458b;
    }
  }

  #country {
    display: flex;
    align-items: center;

    select {
      margin: 0 -15px 0 -30px;
      padding: 0 15px 0 30px;
    }

    &::before {
      display: inline-flex;
      content: '';
      width: 21px;
      height: 15px;
      background: url(../../assets/svgs/flags.svg);
      background-position: -1000px -1000px;
      background-repeat: no-repeat;
      margin-right: 10px;
    }

    &.ad::before {
      background-position: -10px -10px;
    }

    &.ae::before {
      background-position: -41px -10px;
    }

    &.ag::before {
      background-position: -72px -10px;
    }

    &.am::before {
      background-position: -103px -10px;
    }

    &.ar::before {
      background-position: -134px -10px;
    }

    &.at::before {
      background-position: -165px -10px;
    }

    &.au::before {
      background-position: -196px -10px;
    }

    &.be::before {
      background-position: -227px -10px;
    }

    &.bf::before {
      background-position: -258px -10px;
    }

    &.bg::before {
      background-position: -289px -10px;
    }

    &.bo::before {
      background-position: -320px -10px;
    }

    &.br::before {
      background-position: -351px -10px;
    }

    &.ca::before {
      background-position: -382px -10px;
    }

    &.cd::before {
      background-position: -413px -10px;
    }

    &.cg::before {
      background-position: -444px -10px;
    }

    &.ch::before {
      background-position: -475px -10px;
    }

    &.cl::before {
      background-position: -506px -10px;
    }

    &.cm::before {
      background-position: -10px -35px;
    }

    &.cn::before {
      background-position: -41px -35px;
    }

    &.co::before {
      background-position: -72px -35px;
    }

    &.cr::before {
      background-position: -103px -35px;
    }

    &.cz::before {
      background-position: -134px -35px;
    }

    &.de::before {
      background-position: -165px -35px;
    }

    &.dj::before {
      background-position: -196px -35px;
    }

    &.dk::before {
      background-position: -227px -35px;
    }

    &.dz::before {
      background-position: -258px -35px;
    }

    &.ec::before {
      background-position: -289px -35px;
    }

    &.ee::before {
      background-position: -320px -35px;
    }

    &.eg::before {
      background-position: -351px -35px;
    }

    &.es::before {
      background-position: -382px -35px;
    }

    &.eu::before {
      background-position: -413px -35px;
    }

    &.fi::before {
      background-position: -444px -35px;
    }

    &.fo::before {
      background-position: -475px -35px;
    }

    &.fr::before {
      background-position: -506px -35px;
    }

    &.ga::before {
      background-position: -10px -60px;
    }

    &.gb::before {
      background-position: -41px -60px;
    }

    &.gl::before {
      background-position: -72px -60px;
    }

    &.gm::before {
      background-position: -103px -60px;
    }

    &.gr::before {
      background-position: -134px -60px;
    }

    &.gt::before {
      background-position: -165px -60px;
    }

    &.gu::before {
      background-position: -196px -60px;
    }

    &.hk::before {
      background-position: -227px -60px;
    }

    &.hn::before {
      background-position: -258px -60px;
    }

    &.ht::before {
      background-position: -289px -60px;
    }

    &.hu::before {
      background-position: -320px -60px;
    }

    &.id::before {
      background-position: -351px -60px;
    }

    &.ie::before {
      background-position: -382px -60px;
    }

    &.il::before {
      background-position: -413px -60px;
    }

    &.im::before {
      background-position: -444px -60px;
    }

    &.in::before {
      background-position: -475px -60px;
    }

    &.iq::before {
      background-position: -506px -60px;
    }

    &.ir::before {
      background-position: -10px -85px;
    }

    &.is::before {
      background-position: -41px -85px;
    }

    &.it::before {
      background-position: -72px -85px;
    }

    &.je::before {
      background-position: -103px -85px;
    }

    &.jm::before {
      background-position: -134px -85px;
    }

    &.jo::before {
      background-position: -165px -85px;
    }

    &.jp::before {
      background-position: -196px -85px;
    }

    &.kg::before {
      background-position: -227px -85px;
    }

    &.kn::before {
      background-position: -258px -85px;
    }

    &.kp::before {
      background-position: -289px -85px;
    }

    &.kr::before {
      background-position: -320px -85px;
    }

    &.kw::before {
      background-position: -351px -85px;
    }

    &.kz::before {
      background-position: -382px -85px;
    }

    &.la::before {
      background-position: -413px -85px;
    }

    &.lb::before {
      background-position: -444px -85px;
    }

    &.lc::before {
      background-position: -475px -85px;
    }

    &.ls::before {
      background-position: -506px -85px;
    }

    &.lt::before {
      background-position: -10px -110px;
    }

    &.lu::before {
      background-position: -41px -110px;
    }

    &.lv::before {
      background-position: -72px -110px;
    }

    &.ma::before {
      background-position: -103px -110px;
    }

    &.mg::before {
      background-position: -134px -110px;
    }

    &.mk::before {
      background-position: -165px -110px;
    }

    &.ml::before {
      background-position: -196px -110px;
    }

    &.mm::before {
      background-position: -227px -110px;
    }

    &.mt::before {
      background-position: -258px -110px;
    }

    &.mx::before {
      background-position: -289px -110px;
    }

    &.my::before {
      background-position: -320px -110px;
    }

    &.na::before {
      background-position: -351px -110px;
    }

    &.ne::before {
      background-position: -382px -110px;
    }

    &.ng::before {
      background-position: -413px -110px;
    }

    &.ni::before {
      background-position: -444px -110px;
    }

    &.nl::before {
      background-position: -475px -110px;
    }

    &.no::before {
      background-position: -506px -110px;
    }

    &.nz::before {
      background-position: -10px -135px;
    }

    &.om::before {
      background-position: -41px -135px;
    }

    &.pa::before {
      background-position: -72px -135px;
    }

    &.pe::before {
      background-position: -103px -135px;
    }

    &.pg::before {
      background-position: -134px -135px;
    }

    &.ph::before {
      background-position: -165px -135px;
    }

    &.pk::before {
      background-position: -196px -135px;
    }

    &.pl::before {
      background-position: -227px -135px;
    }

    &.pr::before {
      background-position: -258px -135px;
    }

    &.ps::before {
      background-position: -289px -135px;
    }

    &.pt::before {
      background-position: -320px -135px;
    }

    &.py::before {
      background-position: -351px -135px;
    }

    &.qa::before {
      background-position: -382px -135px;
    }

    &.ro::before {
      background-position: -444px -135px;
    }

    &.ru::before {
      background-position: -475px -135px;
    }

    &.rw::before {
      background-position: -506px -135px;
    }

    &.sa::before {
      background-position: -10px -160px;
    }

    &.se::before {
      background-position: -41px -160px;
    }

    &.sg::before {
      background-position: -72px -160px;
    }

    &.si::before {
      background-position: -103px -160px;
    }

    &.sk::before {
      background-position: -134px -160px;
    }

    &.sl::before {
      background-position: -165px -160px;
    }

    &.sn::before {
      background-position: -196px -160px;
    }

    &.so::before {
      background-position: -227px -160px;
    }

    &.sv::before {
      background-position: -258px -160px;
    }

    &.td::before {
      background-position: -289px -160px;
    }

    &.th::before {
      background-position: -320px -160px;
    }

    &.tj::before {
      background-position: -351px -160px;
    }

    &.tl::before {
      background-position: -382px -160px;
    }

    &.tr::before {
      background-position: -413px -160px;
    }

    &.tw::before {
      background-position: -444px -160px;
    }

    &.tz::before {
      background-position: -475px -160px;
    }

    &.ua::before {
      background-position: -506px -160px;
    }

    &.us::before {
      background-position: -10px -185px;
    }

    &.uy::before {
      background-position: -41px -185px;
    }

    &.ve::before {
      background-position: -72px -185px;
    }

    &.vn::before {
      background-position: -103px -185px;
    }

    &.xx::before {
      background-position: -134px -185px;
    }

    &.ye::before {
      background-position: -165px -185px;
    }

    &.za::before {
      background-position: -196px -185px;
    }
  }

  /* Payment Methods */

  #payment-methods {
    margin: 0 0 20px;
    border-bottom: 2px solid #e8e8fb;

    li {
      display: none;

      &.visible {
        display: inline-block;
        margin: 0 20px 0 0;
        list-style: none;
      }
    }

    input {
      display: none;
    }

    label {
      display: flex;
      flex: 1;
      cursor: pointer;
    }

    input + label {
      position: relative;
      padding: 5px 0;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 13px;
    }

    label {
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: -2px;
        left: 0;
        border-bottom: 2px solid #6772e5;
        opacity: 0;
        transform: scaleX(0);
        transition: all 0.25s ease-in-out;
      }

      &:hover {
        color: #6772e5;
        cursor: pointer;
      }
    }

    input:checked + label {
      color: #6772e5;
    }

    label:hover::before,
    input:checked + label::before {
      opacity: 1;
      transform: scaleX(1);
    }

    display: none;
  }

  .payment-info {
    display: none;

    &:not(.card) {
      margin-bottom: 15px;
    }

    &.ideal {
      margin-bottom: 0;
    }
  }

  #payment-methods.visible {
    display: block;
  }

  .payment-info {
    &.visible {
      display: block;
    }

    &.card.visible,
    &.sepa_debit.visible {
      text-align: center;
    }

    p.notice {
      font-size: 14px;
      color: #8898aa;
      text-align: left;
    }
  }

  #wechat-qrcode img {
    margin: 0 auto;
  }

  .element-errors {
    display: inline-flex;
    height: 20px;
    margin: 15px auto 15px;
    padding-left: 20px;
    color: #e25950;
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    background: url(../../assets/svgs/error.svg) center left no-repeat;
    background-size: 15px 15px;

    &.visible {
      opacity: 1;
      transform: translateX(-20px);
    }
  }

  .element-warning {
    display: inline-flex;
    height: 20px;
    margin: 15px auto 15px;
    padding-left: 20px;
    color: #ffa600;
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    background: url(../../assets/svgs/warning.svg) center left no-repeat;
    background-size: 15px 15px;

    &.visible {
      opacity: 1;
      transform: translateX(-20px);
    }
  }

  #iban-errors {
    margin-top: -20px;
  }

  .card-number {
    padding-left: 8px;
    white-space: nowrap;
    font-family: 'Source Code Pro', monospace;
    color: #0d2b3e;
    font-weight: 500;

    span {
      display: inline-block;
      width: 8px;
    }
  }

  &.dark {
    fieldset {
      background: #1b1c24;
      box-shadow: none;

      label {
        color: #ccc;

        &:not(:last-child) {
          border-bottom: 1px solid #565964;
        }
      }
    }

    p {
      &.instruction {
        background: #1b1c24;
      }
    }

    .field {
      color: #fff;

      &::-webkit-#003E4A-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: #666;
      }
    }

    input {
      color: #fff;
    }

    select {
      color: #fff;

      &:focus {
        color: #003e4a;
      }
    }

    ::-webkit-input-placeholder {
      color: #666;
    }

    ::-moz-placeholder {
      color: #666;
    }

    :-ms-input-placeholder {
      color: #666;
    }

    input:-webkit-autofill,
    select:-webkit-autofill {
      -webkit-text-fill-color: #aab7c4;
    }

    button {
      background: #aab7c4;
      color: #1b1c24;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      &:focus {
        background: #555abf;
      }

      &:hover {
        box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
      }

      &:active {
        background: #43458b;
      }
    }

    #payment-methods {
      border-bottom: 2px solid #e8e8fb;

      label {
        &::before {
          border-bottom: 2px solid #6772e5;
        }

        &:hover {
          color: #6772e5;
        }
      }

      input:checked + label {
        color: #6772e5;
      }
    }

    .element-errors {
      color: #ff4c4c;
    }

    .card-errors {
      color: #ff4c4c;
    }

    .card-number {
      color: #fff;
    }
  }
}
